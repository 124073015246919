<template>
  <v-data-table :headers="headers" :items="buses" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("buses") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="650px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Bus") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black" dark>
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-bus-multiple</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <v-form v-model="valid" refs="form">
              <v-container class="mt-3" >
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      v-model="editedItem.name"
                      :label="$t('Name')"
                      :counter="45"
                      :rules="rules"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                      dense
                      v-model="editedItem.bus_type"
                      :label="$t('Type')"
                      :items="bus_types"
                      required
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      v-model="editedItem.host"
                      :label="$t('Host')"
                      :counter="50"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model.number="editedItem.port"
                        :label="$t('Port')"
                        :counter="6"
                        type="number"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        dense
                        v-model="editedItem.com"
                        :label="$t('Com')"
                        :counter="6"
                        type="number"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12" md="4" class="" >
                        <v-select
                          dense
                          v-model="editedItem.baud"
                          :label="$t('Baud')"
                          :items="bauds"
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" class="" >
                        <v-select
                          dense
                          v-model.number="editedItem.data_bits"
                          :label="$t('Databits')"
                          :items="databits"
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" class="">
                        <v-select
                          dense
                          v-model.number="editedItem.parity"
                          :label="$t('Parity')"
                          :items="parities"
                          item-text="name"
                          item-value="val"
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="4" class="" >
                        <v-select
                          dense
                          v-model.number="editedItem.stop_bits"
                          :label="$t('Stopbits')"
                          :items="stopbits"
                          persistent-hint
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>  
                  <v-col cols="12" >
                    <v-row>
                      <v-col cols="12" md="4">
                        <v-text-field
                          dense
                          v-model.number="editedItem.poll_timeout"
                          :label="$t('Poll timeout')"
                          :counter="6"
                          type="number"
                          required
                          outlined
                          suffix="ms"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          dense
                          v-model.number="editedItem.read_timeout"
                          :label="$t('Read timeout')"
                          :counter="6"
                          type="number"
                          required
                          outlined
                          suffix="ms"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          dense
                          v-model.number="editedItem.general_timeout"
                          :label="$t('General timeout')"
                          :counter="6"
                          type="number"
                          required
                          outlined
                          suffix="ms"
                          hide-spin-buttons
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </v-col>
                
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close">{{$t('Cancel')}}</v-btn>
               <v-spacer></v-spacer>
              <v-btn color="vinland" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:item.parity="{ item }">
        <span> {{parities.find(v => v.val === item.parity).name}}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "buses" ,

  data() {
    return {
        dialog: false,
        dialogDelete: false,
        buses: [],
        valid: false,
        loading: false,
        loading2: false,
        rules: [
          v => v !== ''||  this.$t('Item is required'),
          v => v.length <= 45|| this.$t('A maximum  characters exceeded')
        ],
        bus_types: ['LAN', 'SERIAL','MULTIPORT', 'VIRTUAL', 'SCREEN', 'BLUETOOTH', ],
        bauds: [115200, 19200, 9600,],
        parities: [
            {val: 0, name: this.$t('None')},
            {val: 2, name: this.$t('Even')},
            {val: 3, name: this.$t('Odd')},
        ],
        databits: [8,7],
        stopbits: [1,2],
        editedItem: {
          id: null,
          name: '',
          bus_type: '',
          host: '',
          port: null,
          com: '' ,
          baud: null,
          parity: null,
          data_bits: null,
          stop_bits: null,
          poll_timeout: null,
          read_timeout: null,
          general_timeout: null,

        
        },
        defaultItem: {
          id: null,
          name: '',
          bus_type: '',
          host: '',
          port: null,
          com: '' ,
          baud: null,
          parity: null,
          data_bits: null,
          stop_bits: null,
          poll_timeout: null,
          read_timeout: null,
          general_timeout: null,
        },
        editedIndex: -1,
        search: '',
        headers: [
          {
            text: this.$t('ID'),
            align: 'start',
            sortable: true,
            value: 'id',
          },
          {
            text: this.$t('Name'),
            align: 'center',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('Type'),
            align: 'center',
            sortable: true,
            value: 'bus_type',
          },
          {
            text: this.$t('host'),
            align: 'center',
            sortable: true,
            value: 'host',
          },
          {
            text: this.$t('port'),
            align: 'center',
            sortable: true,
            value: 'port',
          },
          {
            text: this.$t('com'),
            align: 'center',
            sortable: true,
            value: 'com',
          },
          {
            text: this.$t('Baud'),
            align: 'center',
            sortable: true,
            value: 'baud',
          },
          {
            text: this.$t('Parity'),
            align: 'center',
            sortable: true,
            value: 'parity',
          },
          {
            text: this.$t('Data bits'),
            align: 'center',
            sortable: true,
            value: 'data_bits',
          },
          {
            text: this.$t('Stop bits'),
            align: 'center',
            sortable: true,
            value: 'stop_bits',
          },
          {
            text: this.$t('Poll timeout'),
            align: 'center',
            sortable: true,
            value: 'poll_timeout',
          },
          {
            text: this.$t('Read timeout'),
            align: 'center',
            sortable: true,
            value: 'read_timeout',
          },
          {
            text: this.$t('Gen. timeout'),
            align: 'center',
            sortable: true,
            value: 'general_timeout',
          },


          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
          },
        
        
        ]
        
    }
  },
  methods: {
      async initialize () {
        try {
          this.loading = true
          const bus = await this.$http.get("/bus")
          this.buses = bus.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      
    },
    
    editItem (item) {
      this.editedIndex = this.buses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.buses.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = {};
      try {
          res = await this.$http.delete(`/bus/${this.editedItem.id}`)
          this.buses.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.closeDelete()
        } 
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          res = await this.$http.put("/bus", this.editedItem)
          Object.assign(this.buses[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
        
      } else {
        try {
          this.loading2 = true
          await this.$http.post("/bus", this.editedItem)
          this.buses.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
      await this.initialize()
    },
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add New Bus') : this.$t('Edit Bus')
      },
      computedHex() {
        return   parseInt(this.editedItem.address).toString(16)  ||  0
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

<style scoped>
.vinland {
  background-color: #196619 !important;
}
</style>